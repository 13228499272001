import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const CpanelVideo = lazy(() => import('sections/hero/CpanelVideo.js'));
const Cpanel = lazy(() => import('sections/services/Cpanel.js'));
const Garantiab = lazy(() => import('sections/hero/Garantia30b.js'));
const ServicesCpanel = lazy(() => import('sections/services/ServicesCpanel.js'));
const BotonCpanel = lazy(() => import('sections/services/BotonCpanel.js'));
const TecCpanel = lazy(() => import('sections/services/TecCpanel.js'));
const FaqHosting = lazy(() => import('sections/services/FaqHosting.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>Hosting cPanel: Servicio de calidad | Pereira, Medellín, Colombia</title>
          <meta name="description" content="Explora el hosting cPanel avanzado en Pereira, Medellín, Bogotá y toda Colombia. Rendimiento destacado en discos SSD. Garantía de reembolso." />
        </Helmet>
        <Layout>
          <CpanelVideo />          
          <Cpanel />
          <Garantiab />          
          <TecCpanel />
          <ServicesCpanel />
          <FaqHosting />
          <BotonCpanel />
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`